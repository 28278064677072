
.header-white
    .page-header.navbar
    .hor-menu
    .navbar-nav
    > li.mega-menu-dropdown
    > .dropdown-menu
    .mega-menu-content
    .mega-menu-submenu
    li
    > a:hover {
        text-decoration: none;
        color: #70da98;
    }

.page-header.navbar
    .page-header-inner
    .top-menu
    .navbar-nav
    > li.dropdown-user
    > .dropdown-menu
    li
    > a:hover {
        text-decoration: none;
        color: #70da98;
    }


.page-header.navbar
    .page-header-inner
    .top-menu
    .navbar-nav
    > li.dropdown-user:hover
    > .dropdown-menu {
        display: block;
    }



