
  .form-group input{

      border-radius: 4px;
    
  }
  
  
  .form-control:focus {
    border-color: #548f62 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(91, 151, 79, 0.6) !important;
  }


  .form-control::placeholder {
    opacity: 0.5;
  }


  /* SELECT */
  /* .css-b62m3t-container:focus{
    border-color: #548f62 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(91, 151, 79, 0.6) !important;
  } */


  .react-datepicker__input-container input {
      
        display: block;
        width: 100%;
        height: 39px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
            border-top-color: rgb(204, 204, 204);
            border-right-color: rgb(204, 204, 204);
            border-bottom-color: rgb(204, 204, 204);
            border-left-color: rgb(204, 204, 204);
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        outline: 0 !important;
        box-shadow: none !important;

  }

  .react-datepicker__input-container input:focus {
    border-color: #548f62 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(91, 151, 79, 0.6) !important;
  
  }


  .react-datepicker__input-container input::placeholder {
    opacity: 0.5;
    color: #6c757d;
  
  }


  .errorSpan {
    font-size: 14px;
    padding: 3px;
    color: rgb(243, 35, 35);
}



  /* .form-group input::placeholder {
    opacity: 0.5 !important;
    color: #6c757d !important;
  
  } */


 