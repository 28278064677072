/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/




    /*==========  Mobile First Method  ==========*/

    /* Custom, iPhone Retina */ 
    @media only screen and (min-width : 320px) {
        
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 480px) {

    }

    /* Small Devices, Tablets */
    @media only screen and (min-width : 768px) {

    }

    /* Medium Devices, Desktops */
    @media only screen and (min-width : 992px) {

    }

    /* Large Devices, Wide Screens */
    @media only screen and (min-width : 1200px) {

    }



    /*==========  Non-Mobile First Method  ==========*/

    /* Large Devices, Wide Screens */
    @media only screen and (max-width : 1200px) {

    }

    /* Medium Devices, Desktops */
    @media only screen and (max-width : 992px) {

    }

    /* Small Devices, Tablets */
    @media only screen and (max-width : 768px) {

    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (max-width : 480px) {

    }

    /* Custom, iPhone Retina */ 
    @media only screen and (max-width : 320px) {
        
    }
    
    
    
/*=====================================================
=            Bootstrap 2.3.2 Media Queries            =
=====================================================*/
@media only screen and (max-width : 1200px) {

}

@media only screen and (max-width : 979px) {

}

@media only screen and (max-width : 767px) {

}

@media only screen and (max-width : 480px) {

}

@media only screen and (max-width : 320px) {

}


    /* default styles here for older browsers. 
       I tend to go for a 600px - 960px width max but using percentages
    */
    @media only screen and (min-width:960px){
        /* styles for browsers larger than 960px; */
    }
    @media only screen and (min-width:1440px){
        /* styles for browsers larger than 1440px; */
    }
    @media only screen and (min-width:2000px){
        /* for sumo sized (mac) screens */
    }
    @media only screen and (max-device-width:480px){
       /* styles for mobile browsers smaller than 480px; (iPhone) */
    }
    @media only screen and (device-width:768px){
       /* default iPad screens */
    }
    /* different techniques for iPad screening */
    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
      /* For portrait layouts only */
    }

    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
      /* For landscape layouts only */
    }

  /*******Nuevos mensajes de error******/
  
.new-message-box {
    /* margin: 15px 0; */
    padding-left: 20px;
    /* margin-bottom: 25px!important; */
}

.new-message-box p{
    font-size: 1.15em;
    font-weight: 400;
}

.info-tab {
    width: 40px;
    height: 40px;
    display: inline-block;
    position: relative;
    top: 8px;
}

.info-tab {
    float: left;
    margin-left: -23px;
}

.info-tab i::before {
    width: 24px;
    height: 24px;
    box-shadow: inset 12px 0 13px rgba(0,0,0,0.5);
}

.info-tab i::after {
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-bottom-color: #EAEEF4;
    border-left-color: #EAEEF4;
    bottom: -18px;
}

.info-tab i::before, .info-tab i::after {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -17px;
    transform: rotateX(60deg);
}

.note-box, .warning-box, .tip-box-success, .tip-box-danger, .tip-box-warning, .tip-box-info, .tip-box-alert {
    padding: 12px 8px 12px 26px;
}


/***Success****/

.new-message-box-success {
    background: #DCEDC8;
    border-radius: 31px;
    padding: 3px;
    margin: 10px 0;
}

.tip-icon-success {
    background: #8BC34A;
}

.tip-box-success {
    color: #33691E;
    /* background: #DCEDC8; */
}

.tip-icon-success::before {
    font-size: 25px;
    content:"\f00c";
    top: 5px;
    left: 13px;
    font-family: FontAwesome;
    position: absolute;
    color: white;
}

.tip-icon-success i::before {
    background: #8BC34A;
}


/*******Danger*******/
.new-message-box-danger {
    background: #FFCCBC;
    border-radius: 31px;
    padding: 3px;
    margin: 10px 0;
}

.tip-icon-danger {
    background: #f44336;
}

.tip-box-danger {
    color: #b71c1c;
    font-weight: normal;
    /* background: #FFCCBC; */
}

.tip-icon-danger::before {
    font-size: 25px;
    content:"\f00d";
    top: 4px;
    left: 16px;
    font-family: FontAwesome;
    position: absolute;
    color: white;
}

.tip-icon-danger i::before {
    background: #f44336;
}

/*******warning*******/
.new-message-box-warning {
    background: #eeeeee;
    border-radius: 10px;
    padding: 3px;
    margin: 10px 0;
}

.tip-icon-warning {
    background: #FFEB3B;
}

.tip-box-warning {
    color: #212121;
    /* background: #FFF9C4; */
}

.tip-icon-warning::before {
    font-size: 25px;
    content:"\f071";
    top: 8px;
    left: 13px;
    font-family: FontAwesome;
    position: absolute;
    color: #212121;
}

.tip-icon-warning i::before {
    background: #FFEB3B;
}

/*******info*******/
.new-message-box-info {
    background: #B3E5FC;
    border-radius: 31px;
    padding: 3px;
    margin: 10px 0;
}

.tip-box-info {
    color: #01579B;
    /* background: #B3E5FC; */
}

.tip-icon-info {
    background: #03A9F4;
}

.tip-icon-info::before {
    font-size: 25px;
    content:"\f129";
    top: 5px;
    left: 21px;
    font-family: FontAwesome;
    position: absolute;
    color: white
}

.tip-icon-info i::before {
    background: #03A9F4;
}


/*******alert*******/
.new-message-box-alert {
    background: #FFF8E1;
    border-radius: 31px;
    padding: 3px;
    margin: 10px 0;
}

.tip-box-alert {
    color: #212121;
    /* background: #FFF8E1; */
}

.tip-icon-alert {
    background: #FF6F00;
}

.tip-icon-alert::before {
    font-size: 25px;
    content:"\f06a";
    top: 3px;
    left: 14px;
    font-family: FontAwesome;
    position: absolute;
    color: white
}

.tip-icon-alert i::before {
    background: #FF6F00; 
}
