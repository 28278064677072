td a {
	font-size: inherit;
	line-height: 1.7;
	color: #1e07f3;
	margin: 0px;
	transition: all 0.4s;
	-webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
	outline: none !important;
}

a:hover {
	text-decoration: none;
  color: rgb(11, 107, 48);
}