/* @import url('https://fonts.googleapis.com/css?family=Patua One');
@import url('https://fonts.googleapis.com/css?family=Tinos'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&family=Tinos:ital@1&display=swap');


/* LOGIN  */
.login100-form-title {
    text-transform: none !important;
    font-style: oblique;
}

.login100-form-btn:hover {
    color: white !important;
    background: rgb(129, 192, 93);
}

.wrap-login100 {
    width: 500px;
    border-radius: 10px;
    overflow: hidden;
    padding: 37px 55px;
    background: #6f824f;
    background: linear-gradient(to bottom,#6f824f,#809955) !important;
    margin-right: 694px;
}


/* EHCP TAB */
.centerText {
    text-align: center;
}

/* STUDENT PROFILE MEDICAL */
.bigger {
    font-size: 19px
}


/* STUDENT PROFILE MEDICAL NO NIN BUTTON*/

.button-noNin {
    background: 0 0;
    border: none;
    border-radius: 2px;
    color: #000;
    /* position: relative; */
    height: 31px;
    margin: 0;
    min-width: 64px;
    padding: 0 14px;
    display: inline-block;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0;
    overflow: hidden;
    will-change: box-shadow;
    transition: box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1);
    outline: none;
    cursor: pointer;
    text-decoration: none;
    /* text-align: center; */
    line-height: 30px;
    vertical-align: middle;
}

/* PAGE CONTENT WARPPER */
.fence {
    background-image: linear-gradient(transparent, rgb(180, 178, 177)),  url("https://res.cloudinary.com/dlkpi3swo/image/upload/v1699283154/gate_v0i4aj.jpg");
    background-size:     cover;                     
    background-repeat:   no-repeat;
}

/* breadcrumb page title */
.page-container-bg-solid .page-title,
.page-content-white .page-title {
    color: #6f824f;
    margin-bottom: 6px;
    margin-top: 6px;
}
.page-container-bg-solid .page-title small,
.page-content-white .page-title small {
    color: #6f824f;
}
.page-content-white .page-title {
    font-size: 40px;
}

.page-bar {
    padding: 0;
    background-color: #f1f4f7;
    margin: 6px -20px 6px !important;
    border-radius: 4px;
}

/* BUTTONS EDIT IN BREADCRUMb */
.page-bar .page-breadcrumb-button {
    display: inline-block;
    float: left;
    padding: 8px;
    margin: 0;
    list-style: none;
    background-color: transparent;
    border-radius: 50px;
    
    padding-left: 20px !important;
}

.page-container-bg-solid .page-bar .page-breadcrumb,
.page-content-white .page-bar .page-breadcrumb-button {
    padding: 15px 15px;
    margin-top: 10px;
}

.page-bar .page-breadcrumb-button > li {
    display: inline-block;
}

.page-bar .page-breadcrumb-button > li :hover{
    background-color: #6f824f !important;
    color: #6f824f;
    border-left: 1px solid #6f824f;
    border-right: 1px solid #6f824f;
    border-bottom: 1px solid #fff;
    border-top: 1px solid #6f824f;
}

/* Navar Loge left */
.logo-indigo .page-header.navbar .page-logo{
	background: #6f824f;
}

/* Navbar logo left */
.varLogo {
    max-width: 273px;
}

.page-header.navbar .page-logo {
    float: left;
    display: block;
    width: 287px;
    height: 70px;
    padding: 10px 0px 10px 5px;
    background: #222c3c;
}


/* Navar picture and name when logged in */
.page-header.navbar
    .top-menu
    .navbar-nav
    > li.dropdown-user
    .dropdown-toggle
    > img {
    float: left;
    margin-top: -10px;
    margin-right: 5px;
    height: 51px;
    display: inline-block;
}

.page-header.navbar
    .top-menu
    .navbar-nav
    > li.dropdown-user
    .dropdown-toggle
    > .username {
    display: inline-block;
    font-size: 20px;
    font-weight: 300;
}

.page-header.navbar
    .top-menu
    .navbar-nav
    > li.dropdown-user
    .dropdown-toggle
    > i {
    display: inline-block;
    margin: 0;
    font-size: 17px;
}

/* SUBMENU UNDER NAME */
.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-menu {
    width: 80px;
    height: 68px;
}



.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-menu > li > a {
    font-size: 18px;
    font-weight: 300;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-menu > li :hover {
    background-color: #6f824f;
    color: #eff7d5 !important;
}



/* Navar notifications icon */
.page-header.navbar .top-menu .navbar-nav > li.dropdown > .forDown {
    margin: 0;
    padding: 20px 10px 9px 0;
    display: block;
    position: relative;
}

.page-header.navbar .top-menu .navbar-nav > li.dropdown > .forDown > i {
    font-size: 35px;
} 

.badge {
    font-size: 14px !important;
    height: 20px;
    color: #fff;
    -webkit-border-radius: 12px !important;
    -moz-border-radius: 12px !important;
    border-radius: 12px !important;
    text-shadow: none !important;
    text-align: center;
}


/* menu options */
.page-header.navbar .hor-menu .navbar-nav > li > a {
    font-size: 18px;
    font-weight: 400;
    padding: 19px 13px 18px 13px;
    min-height: 60px;
    float: left;
}

.page-header.navbar .hor-menu .navbar-nav > li > a > i {
    color: #c2cad8;
    font-size: 25px;
    margin: 0px 2px;
}

/* MENU STUDENTS */
.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content {
    padding: 0px;
}

/* Menu sub options */
.page-header.navbar
    .hor-menu
    .navbar-nav
    > li.mega-menu-dropdown
    > .dropdown-menu
    .mega-menu-content
    .mega-menu-submenu
    li
    > a {
    display: block;
    white-space: normal;
    font-family: Poppins, sans-serif;
    padding: 7px;
    margin: 0;
    font-size: 18px;
    font-weight: 300;
}

.page-header.navbar
    .hor-menu
    .navbar-nav
    > li.mega-menu-dropdown
    > .dropdown-menu
    .mega-menu-content
    .mega-menu-submenu
    li
    :hover {
    background-color: #6f824f;
    color: #eff7d5 !important;  
    }
    

.page-header.navbar
    .hor-menu
    .navbar-nav
    > li.mega-menu-dropdown
    > .dropdown-menu
    .mega-menu-content
    .mega-menu-submenu
    li
    :hover > i {
    
        color: #fbfcf8 !important;
    
}

.header-white .page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li :hover > i  {
    color: rgb(252, 249, 249) !important;
}

/* .header-white .page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li :hover > i  {
    color: rgb(252, 249, 249) !important;
} */




.header-white .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu {
    box-shadow: 4px 4px rgba(63, 79, 98, .2) !important;
    background: #f1efef;
}




/* picture in cards */
.doctor-pic {
    padding: 3px;
    border: 3px solid rgb(210, 214, 222);
    border-radius: 20% 20% 20% 20%;
    max-width: 102px !important;
}

.doctor-pic:hover {
    transform: scale(2.04);
}

/* list top line colour */
.card-topline-aqua {
    border-top: 3px solid #6f824f;
}


/* Picture in detailed profile */
.profile-userpic img {
    margin: 0px auto;
    width: 130px;
    padding: 3px;
    border: 3px solid #6f824f;
    border-radius: 50% 50% 50% 50%;
}

/* card in detailed profile */
.card-head {
    border-radius: 2px 2px 0 0;
    border-bottom: 1px dotted #6f824f;
    padding: 2px;
    /* text-transform: uppercase; */
    color: #6f824f;
    font-size: 14px;
    font-weight: 600;
    line-height: 40px;
    min-height: 40px;
}

/* PAGE CONTAINER MAGIN BOTTON TO GET GAP BETWEEN FOOTER AND ITSELF */
.page-full-width.page-header-fixed .page-container {
    margin-top: 110px;
    margin-bottom: 37px;
}

/* FOOTER */
.page-footer {

    padding: 10px 5px;
    font-size: 15px;
    background-color: #6f824f;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: 10px !important;
    
}  

.page-footer .page-footer-inner {
    color: #fafbfc;
}


/* NOTIFICATIONS */
.bgNoti-warning {
    color: rgb(2, 2, 2);
    background-color: #f8eba0ec; 
}

.bgNoti-warning 
    > a
    > span
    > i {
        color: rgb(75, 73, 73);
}

.bgNoti-info {
    color: #fff;
    background-color: #8dbad8;
}

.bgNoti-info 
    > a
    > span
    > i {
        color: #fff;
}

.bgNoti-error {
    color: #fff;
    background-color: #f4ada5;
}

.bgNoti-error 
    > a
    > span
    > i {
        color: #fff;
}

.info-box-icon
    > i {
        font-size: 71px;
    }

.info-box-icon.push-bottom {
    margin-top: 7px;
}

/* REACT BOOTSTRAP TABS */

.nav-link {
    display: block;
    padding: .5rem 1rem;
    font-size: 20px !important;
    line-height: 37px !important;
    /* border-radius: 50% 50% 50% 50%; */
    /* font-weight: $nav-link-font-weight; */
    color: #fff;
    /* background-color: rgb(116, 103, 103); */
}

.nav-tabs .nav-link {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    border-left: 1px dashed #6f824f;
    border-right: 1px dashed #6f824f;
    border-bottom: 1px solid #fff;
    border-top: 1px dashed #6f824f;
    color: rgba(8, 8, 8, 0.349);
}


/* .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active { */
.nav-tabs .nav-link.active {
    color: #6f824f;
    border-top: 1px solid #6f824f;
    border-right: 1px solid #6f824f;
    border-left: 1px solid #6f824f;
    border-bottom: 1px solid #fff;
    background-color: #fff;
    /* border-color: none; */
}

.nav-tabs {
    border-bottom: none;
    margin-bottom: 0px !important;
}

.redLetters {
    color: #e74c3c !important;
    border-top: 1px solid #e74c3c !important;
    border-right: 1px solid #e74c3c !important;
    border-left: 1px solid #e74c3c !important;
}

.nav-tabs > li {
    margin-right: 5px;
}

.nav-tabs > div {
    margin-right: 5px;
}



/* .nav-tabs .nav-link::-moz-focus-inner {
    border-bottom: 2px solid #fff !important;
} */

.nav-tabs .nav-link:hover{
    background-color: #fff !important;
    color: #6f824f;
    border-left: 1px solid #6f824f;
    border-right: 1px solid #6f824f;
    border-bottom: 1px solid #fff;
    border-top: 1px solid #6f824f;
}

.tab-content {
    background-color: #fff;
    padding-top: 20px;
    border-left: 1px solid #6f824f;
    border-right: 1px solid #6f824f;
    border-bottom: 1px solid #6f824f;
    border-top: 1px solid #6f824f;
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
}

/* PROFILE BUTTONS */

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, 
.btn-primary.active, .btn-primary.focus, .btn-primary:active, 
.btn-primary:focus, .btn-primary:hover, .open > .dropdown-toggle.btn-primary {
    background-color: #6f824f !important;
    border: 1px solid #6f824f !important;
    color: #fff !important;
}

/* MODAL BUTTONS */

.btn-modal {
    color: #fff;
    background-color: #6f824f;
    border-color: #6f824f;
}

.btn-modal:hover{
    color: rgb(0, 0, 0);
    background-color: #b4d181;
    border-color: #b4d181;
}


.btn-secondary:hover{
    color: rgb(0, 0, 0);
    background-color: #c0c2bd;
    border-color: #c0c2bd;
}

/* MODAL */
.modal .modal-content {
    border-radius: 13px;
}

.modal .modal-content .modal-title {
    font-weight: 400;
    width: 100%;
}

.modal-header {
    /* display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between; */
    padding: 1rem 1rem 0rem 1rem;
    background-color: #b4d181;
    border-radius: 13px 13px 0px 0px;
    font-size: 1.5rem;
    /* border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px); */
}

.modal-header .btn-close {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    
    margin-top: -1rem;
    margin-right: 0rem;
    margin-bottom: -0.5rem;
    margin-left: 1rem;;
}

/* .modal-body {
    overflow: visible;
} */

/* .modal-title > * {
    font-size: 2.0rem;
} */

.fake-modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
}

.fake-modal-footer > * {
    margin: .25rem;
}

/* DATE PICKER */

.react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.9rem !important;
    background-color: #eff7d5 !important;
    color: #85970a !important;
    border: 1px solid #828448 !important;
    border-radius: 0.3rem !important;
    display: inline-block !important;
    position: relative !important;
    width: 250px !important;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
    border-radius: 0.3rem !important;
    background-color: #58aa4c !important;
    color: #fff !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    border-radius: 0.3rem !important;
    background-color: #58aa4c !important;
    color: #fff !important;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
    background-color: #58aa4c !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    margin-top: -4px !important;
    color: #000 !important;
    font-weight: bold !important;
    font-size: 1.2rem !important;
}

.react-datepicker__month {
    width: 250px !important;
}

.react-datepicker__header {
    width: 248px !important;
}

.react-datepicker__day-names {
    margin-top: 3px;
    font-size: 1rem;
}


.react-datepicker__navigation {   
    top: 8px !important;
}


/* TOASTY  */
.Toastify__toast-icon {
    -webkit-margin-end: 10px;
    margin-inline-end: 20px !important;
    width: 35px !important;
    flex-shrink: 0;
    display: flex;
}

.info-box {
    border-radius: 80px !important;
    padding-top: 9px !important;
    padding-right: 9px !important;
    padding-bottom: 9px !important;
    padding-left: 9px !important;
  }


.Toastify__toast {
    overflow: hidden;
  }   

.Toastify__toast-container{

    font-size: 18px !important;
    font-weight: 499;
    line-height: 1.7 !important;
    width: 420px !important;
}


/* TABLE TITLES */
.MuiTypography-h6 {
    font-size: 2.25rem !important;
    color: #6f824f !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
  }


/* LIST FOR SUPPORT */
.list-group-support {
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: .25rem;
}

/* CHARTS */

.chartCard {
    background: #fff; 
    border-top: 3px solid #6f824f;
    border-radius: 10px;
    margin-right: 10px;
    padding-top: 10px;
}

.chartWrap {
    display: flex;
    margin-bottom: 30px;
    height: 500px;
}



.chartCard .getDown {
    margin-top: '188px';
}

.chartCard .getUp {
    margin-bottom: '170px';
}

/* PICTURE MODAL */
/* width: 58, borderRadius: '40%' */
.pic-list{
    width: 58px;
    border-radius: 40%;
}

.pic-list:hover {
    transform: scale(2.04);
}

.__react_modal_image__medium_img{
    width: 800px !important;
}

.__react_modal_image__modal_container{
    z-index: 99999 !important;
}


/* TABLE FILTERING ROW */
.css-1dc80h3-MuiTableCell-root {
    padding: 4px !important;
}

.css-73b76c-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10.5px 14px !important;
}    

/* TABLE STATS */

.statable {
    /* border-collapse: collapse; */
    width: 88%;
    margin: 0 auto;
    border-radius: 10px;
}

.statable, th, td {
    text-align: left;
    padding: 8px;
}

tr:nth-child(even){background-color: #f2f2f2}

th {
    background-color: #6f824f;
    color: white;
}

/* KEEP LINE JUMP */
.keepJump {
    white-space: pre-wrap;
}


/* NEW HEADERS */

.box {
    width: 100%;
    min-width: 250px;
    display: block;
    height: 72px;
    position: relative;
    border-radius: 5px;
    background: linear-gradient(to right, #bac9a0 35%, #d6e2ad 100%);
    margin-bottom: 25px;
    margin-top: 32px;
    padding: 15px 25px 0 40px;
    color: darkslategray;
    box-shadow: 1px 2px 1px -1px #777;
    transition: background-color 200ms ease-in-out;
}

.shadow {
  position: relative;
}

.box.shadow p {
    font-size: 40px;
    margin-top: -14px;
    margin-left: 20px;
    font-family: 'Tinos', serif;
    font-weight: bold;
    /*margin-bottom: 10px; */
}

.shadow::before {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 13px;
    right: 7px;
    width: 25%;
    top: 0;
    box-shadow: 0 15px 10px rgb(31, 30, 30);
    transform: rotate(4deg);
    transition: all 150ms ease-in-out;
}

.circle {
    position: absolute;
    top: 23px;
    left: 15px;
    border-radius: 50%;
    box-shadow: inset 1px 1px 1px 0px rgba( 0, 0, 0, .5), inset 0 0 0 25px #eef1f5;
    width: 24px;
    height: 24px;
    display: inline-block;
}


/* NOTEBOOK */

@import url(https://fonts.googleapis.com/css?family=Indie+Flower);

.paper {
    position: relative;
    background: rgba(255,255,255,0.9);
    box-shadow: 0px 0px 5px 0px #888;
    margin-right: 10px;
    /* transform: rotate(1deg); */
}
.cuanto32 {
    width: 32%;
}

.cuanto49 {
    width: 49%;
}

.cuanto19 {
    width: 19%;
}

.cuanto99 {
    width: 99%;
}

.textCen {
    text-align: center;
}

.paper::before {
    content: '';
    position: absolute;
    margin-left: 40px;
    height: 100%;
    width: 2px;
    background: rgba(255,0,0,0.4);
}
.lines {
  margin-top: 40px;
  height: calc(100% - 40px);
  width: 100%;
  background-image: repeating-linear-gradient(white 0px, white 24px, steelblue 25px);
}
.text {
    /* position: absolute; */
    /* top: 65px;
    left: 55px;
    bottom: 10px;
    right: 10px; */
    margin-bottom: 10px;
    font-size: 19px;
    margin-left: 50px;
    line-height: 25px;
    font-family: 'Indie Flower';
    overflow: hidden;
    outline: none;
}

.text p {
    font-family: 'Indie Flower';
    font-weight: bold;
    font-size: 19px;
}
.holes {
    position: absolute;
    left: 10px;
    height: 25px;
    width: 25px;
    background: white;
    border-radius: 50%;
    box-shadow: inset 0px 0px 3px 0px #888;
}
.hole-top {
    top: 10%;
}
.hole-middle {
    top: 45%;
}
.hole-bottom {
    bottom: 10%;
}

/* PIN */

.pin {
    --pin-color: #d6e2ad;
    --pin-dark: #4b9b0b;
    --pin-light: #bac9a0;

    position: absolute;
    left: 20px;
    width: 60px;
    height: 50px;
  }
  
  .sombra {
    position: absolute;
    top: 18px;
    left: -8px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: radial-gradient(var(--paper-shadow), 20%, rgba(201, 191, 141, 0));
  }
  
  .metal {
    position: absolute;
    width: 5px;
    height: 20px;
    background: linear-gradient(to right, #808080, 40%, #eae8e8, 50%, #808080);
    border-radius: 0 0 30% 30%;
    transform: rotate(50deg);
    transform-origin: bottom left;
    top: 15px;
    border-bottom: 1px solid #808080;
  }
  
  .bottom-circle {
    position: absolute;
    right: 15px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: var(--pin-color);
    background: radial-gradient(
        circle at bottom right,
        var(--pin-light),
        25%,
        var(--pin-dark),
        90%,
        var(--pin-color)
    );
  }
  
  /* Barrel */
  .bottom-circle::before {
    content: "";
    position: absolute;
    top: 0;
    left: -2px;
    width: 20px;
    height: 30px;
    transform: rotate(55deg);
    transform-origin: 100% 100%;
    border-radius: 0 0 40% 40%;
    background: linear-gradient(
        to right,
        var(--pin-dark),
        30%,
        var(--pin-color),
        90%,
        var(--pin-light)
    );
  }
  
  /* Top circle */
  .bottom-circle::after {
    content: "";
    position: absolute;
    right: -10px;
    top: -5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: radial-gradient(
        circle at right,
        var(--pin-light),
        30%,
        var(--pin-color),
        var(--pin-dark) 80%
    );
  }