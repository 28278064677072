.errorSpanOut {
    font-size: 18px;
    padding: 3px;
    color: rgb(119, 16, 16);
}

/* .wrap-login100 {
    width: 900px;
} */


.wrap-login100-register {
    width: 900px;
    border-radius: 10px;
    overflow: hidden;
    padding: 37px 55px 37px 55px;
    background: #6f824f;
    background: -webkit-linear-gradient(to bottom,#6f824f,#809955);
    background: -o-linear-gradient(to bottom,#6f824f,#809955);
    background: -moz-linear-gradient(to bottom,#6f824f,#809955);
    background: linear-gradient(to bottom,#6f824f,#809955);
}


