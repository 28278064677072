/*
 *  Document   : plugins.css
 *  Author     : RedStar Theme
 *  Description: stylesheet for third  party plugin customizations
 
 
 				STRUCTURE
 				
 				1. Bootstrap DatePicker
 				2. Bootstrap Daterangepicker
 				3. Bootstrap  Datetimepicker
				4. Bootstrap Colorpicker
				5. Bootstrap Modal
				6. Bootstrap Select
				7. Color variants
				8. Animated Pie Chart
				9. Data tables
				10. Dropzone
				11. Spinner
				12. Fullcalendar
				13. Google Maps
				14. Vector Map
				15. Morris Chart
				16. Select2
				17. iCheck
				18. Color Picker
 				
 				
 */
/**********************************
		1. Bootstrap DatePicker
***********************************/
.datepicker .active,
.datepicker .active:hover,
.datepicker .selected,
.datepicker .today {
  background-image: none !important;
  filter: none !important;
}
.datepaginator a {
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 300;
}
.datePicker input {
  margin-left: 0px;
  margin-right: 0px;
}
.datePicker .dateBtn {
  display: flex;
  align-items: center;
  margin-left: 2px !important;
}
.datePicker .dateBtn .input-button {
  margin: 3px;
}

#dp-calendar {
  right: 4px !important;
}

.datepaginator .fa-angle-right:before {
  content: "";
}

.datepaginator .fa-angle-left:before,
.datepicker .fa-angle-left:before {
  content: "";
}

.datepicker.dropdown-menu {
  padding: 5px;
  border: 1px solid #efefef !important;
  border-radius: 5px !important;
  right: auto;
}
.datepicker th.switch,
.datetimepicker th.switch,
.datetimepicker th.today {
  width: 145px;
  background: #007bff;
  color: #fff;
}
.datepicker thead tr:first-child th:hover {
  background: #4b8df8;
}
.datetimepicker thead tr:first-child th:hover,
.datetimepicker tfoot th:hover {
  background: #4b8df8;
}
.datepicker .selected {
  background-color: #909090 !important;
}

.datepicker .active {
  background-color: #4b8df8 !important;
}

.datepicker .active:hover {
  background-color: #2678fc !important;
}

.datepicker .input-daterange input {
  text-align: left;
}

.datepicker table td {
  color: #000;
  font-weight: 300 !important;
}

.datepicker table th {
  color: #333;
  font-weight: 400 !important;
}

.datepicker .fa-angle-right:before,
.daterangepicker .fa-angle-right:before {
  content: "";
}

/***********************************
	2. Bootstrap Daterangepicker
************************************/

.daterangepicker {
  margin-top: 4px;
}

.daterangepicker .input-mini {
  width: 100% !important;
  outline: 0 !important;
}

.daterangepicker td {
  text-shadow: none;
}

.daterangepicker td.active {
  background-color: #4b8df8;
  background-image: none;
  filter: none;
}

.datetimepicker .active,
.datetimepicker .active:hover {
  filter: none !important;
  background-image: none !important;
}

.daterangepicker th {
  font-weight: 400;
  font-size: 14px;
}

.daterangepicker .ranges input[type="text"] {
  width: 70px !important;
  font-size: 11px;
  vertical-align: middle;
}

.daterangepicker .ranges label {
  font-weight: 300;
  display: block;
}

.daterangepicker .ranges {
  width: 170px;
}

.daterangepicker .ranges ul > li.active {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
}

.daterangepicker .ranges .btn {
  margin-top: 10px;
}

.daterangepicker .ranges li {
  color: #333;
}

.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
  background: #4b8df8 !important;
  border: 1px solid #4b8df7 !important;
  color: #fff;
}

.daterangepicker .range_inputs input {
  margin-bottom: 0 !important;
}

.daterangepicker .fa-angle-left:before,
.datetimepicker .fa-angle-left:before {
  content: "";
}

/***********************************
	3. Bootstrap  Datetimepicker
************************************/

.datetimepicker table td {
  color: #000;
  font-weight: 300 !important;
  padding: 7px;
  text-align: center;
}

.datetimepicker table th {
  font-weight: 400 !important;
}

.datetimepicker .active {
  background-color: #4b8df8 !important;
}
.datetimepicker table td:hover {
  cursor: pointer;
}
.input-append.date .add-on i,
.input-prepend.date .add-on i {
  display: inline-block;
}
.datetimepicker .active:hover {
  background-color: #2678fc !important;
}

.datetimepicker .fa-angle-right:before {
  content: "";
}

/* change z-index when opened in modal */
.modal-open .colorpicker,
.modal-open .datepicker,
.modal-open .daterangepicker {
  z-index: 10055 !important;
}
/***********************************
	4. Bootstrap Colorpicker
************************************/
.colorpicker.dropdown-menu,
.daterangepicker.dropdown-menu,
.datetimepicker.dropdown-menu {
  padding: 5px;
}

.input-group.color .input-group-btn i {
  position: absolute;
  display: block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  right: 6px;
}

.modal-open .datetimepicker {
  z-index: 10055;
}

.editable-input table,
.editable-input table td,
.editable-input table th,
.editable-input table tr {
  border: 0 !important;
}

.editable-input .combodate select {
  margin-bottom: 5px;
}

.fileinput {
  margin-bottom: 0;
}

.fileinput .close {
  float: none;
}

.fileinput .input-group {
  white-space: nowrap;
  overflow: hidden;
}

/***********************************
	5. Bootstrap Modal
***********************************/

.modal {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.modal-open.page-overflow .modal-scrollable,
.modal-open.page-overflow .page-container,
.modal-open.page-overflow .page-container .navbar-fixed-bottom,
.modal-open.page-overflow .page-container .navbar-fixed-top {
  overflow-y: auto !important;
}

.modal-scrollable {
  overflow: hidden !important;
  z-index: 10051;
  overflow-y: scroll !important;
}

.modal-backdrop {
  position: fixed;
  bottom: 0;
}

.modal {
  border-radius: 2px;
  border: 0 !important;
}

.modal .modal-body {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  border: 0;
}

.modal .modal-title {
  font-weight: 400;
}

.modal .modal-footer {
  border: 0 !important;
}

.modal-backdrop,
.modal-backdrop.fade.in {
  opacity: 0.2;
  filter: alpha(opacity=20);
}

/***********************************
	6. Bootstrap Select
***********************************/

.bootstrap-select .btn {
  border-color: #c2cad8;
}

.has-error .bootstrap-select .btn {
  border-color: #fbe1e3;
}

.has-success .bootstrap-select .btn {
  border-color: #abe7ed;
}

.has-warning .bootstrap-select .btn {
  border-color: #f9e491;
}

.bootstrap-select.open .btn,
.bootstrap-select.open.dropup .btn {
  border-color: #93a1bb;
}

.bootstrap-select .btn:focus {
  outline: 0 !important;
  outline-offset: 0;
}

.bootstrap-select.btn-group .dropdown-menu {
  margin-top: 1px;
}

.bootstrap-select.btn-group .dropdown-menu > li > dt > .text {
  font-weight: 600;
  font-family: Poppins, sans-serif;
  font-size: 14px;
}

.bootstrap-select.btn-group .dropdown-menu .text-muted {
  color: #999 !important;
}

.bootstrap-select .caret {
  border: 0;
  width: auto;
  height: auto;
  margin-top: -10px !important;
}

.bootstrap-select .caret:before {
  content: "\f107";
  display: inline-block;
  border: 0;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
}

.bootstrap-select .selected i {
  color: #aaa;
}

.bootstrap-select .dropdown-menu {
  z-index: 9999 !important;
}

/********************************************
	7. Color variants
*********************************************/
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary {
  color: #fff;
  background: #337ab7;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info {
  color: #fff;
  background: #659be0;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success {
  color: #fff;
  background: #36c6d3;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning {
  color: #fff;
  background: #f1c40f;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger {
  color: #fff;
  background: #ed6b75;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default {
  color: #444;
  background: #e8eaef;
}

.bootstrap-timepicker-widget table td a {
  padding: 4px 0;
}

.bootstrap-timepicker-widget input,
.bootstrap-timepicker-widget input:focus {
  outline: 0 !important;
  border: 0;
}

.modal-open .bootstrap-timepicker-widget {
  z-index: 10055 !important;
}

.bootstrap-timepicker-widget.timepicker-orient-bottom:after,
.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
  top: auto;
}

.toast {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #030303;
}

.tabbable-tabdrop .nav-pills,
.tabbable-tabdrop .nav-tabs {
  position: relative;
}

.tabbable-tabdrop .nav-pills .caret,
.tabbable-tabdrop .nav-tabs .caret {
  display: none;
}

.tabbable-tabdrop .nav-pills .tabdrop .dropdown-toggle i,
.tabbable-tabdrop .nav-tabs .tabdrop .dropdown-toggle i {
  font-size: 14px;
}

.CSSAnimationChart a,
.mapChart a {
  color: #bbb !important;
  font-size: 12px !important;
}

.tabbable-tabdrop .dropdown-menu:after {
  right: 10px;
  left: auto;
}

.tabbable-tabdrop .dropdown-menu:before {
  right: 9px;
  left: auto;
}

.wysihtml5-sandbox {
  width: 100% !important;
}

.wysihtml5-toolbar li {
  margin: 0;
  height: 29px;
}

.wysihtml5-toolbar li .dropdown-menu {
  margin-top: 5px;
}

.has-error .wysihtml5-sandbox {
  border: 1px solid #fbe1e3 !important;
}

.has-success .wysihtml5-sandbox {
  border: 1px solid #abe7ed !important;
}

.has-warning .wysihtml5-sandbox {
  border: 1px solid #f9e491 !important;
}

.note-editor {
  border: 1px solid #c2cad8;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
}

.note-editor .note-toolbar {
  border-bottom: 1px solid #c2cad8;
  -webkit-border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  -ms-border-radius: 2px 2px 0 0;
  -o-border-radius: 2px 2px 0 0;
  border-radius: 2px 2px 0 0;
}

.note-editor .note-statusbar {
  -webkit-border-radius: 0 0 2px 2px;
  -moz-border-radius: 0 0 2px 2px;
  -ms-border-radius: 0 0 2px 2px;
  -o-border-radius: 0 0 2px 2px;
  border-radius: 0 0 2px 2px;
}

.note-editor .note-statusbar .note-resizebar {
  border-top: 1px solid #c2cad8;
}

.note-editor.note-frame.fullscreen {
  z-index: 10050;
  width: 100% !important;
}

.note-editor .dropdown-menu:before {
  left: 9px;
  right: auto;
}

.note-editor .dropdown-menu:after {
  left: 10px;
  right: auto;
}

.note-link-dialog .checkbox {
  margin-left: 20px;
}

.has-error .note-editor {
  border: 1px solid #fbe1e3 !important;
}

.has-success .note-editor {
  border: 1px solid #abe7ed !important;
}

.has-warning .note-editor {
  border: 1px solid #f9e491 !important;
}

.gtreetable .dropdown-menu {
  margin-top: 0;
}

.gtreetable .dropdown-menu:after,
.gtreetable .dropdown-menu:before {
  display: none !important;
}

.gtreetable .node-action .form-control {
  position: relative;
  top: 2px;
  display: inline-block;
}

.gtreetable .node-icon-ce,
.gtreetable .node-icon-handle,
.gtreetable .node-icon-selected,
.gtreetable .node-icon-type {
  opacity: 0.6;
  filter: alpha(opacity=60);
}

.bootstrap-tagsinput .tag [data-role="remove"] {
  box-shadow: none;
  font-family: Poppins, sans-serif;
}

.bootstrap-tagsinput .tag [data-role="remove"]:after {
  font-family: Poppins, sans-serif;
}

.bootstrap-tagsinput .tag [data-role="remove"]:hover {
  text-decoration: none;
  box-shadow: none;
}

.rt-bootstrap-tables .rt-btm-transform .bars {
  height: auto;
}

.CSSAnimationChart,
.mapChart {
  width: 100%;
  height: 500px;
}

.fixed-table-toolbar .columns label {
  margin: 10px;
  padding: 0 0 0 30px;
}

.fixed-table-header th.bs-checkbox {
  outline: 0;
}

.fixed-table-header th.bs-checkbox .th-inner {
  overflow: visible;
}

.fixed-table-header th.bs-checkbox .th-inner .rt-chkbox {
  right: -5px;
}

.bootbox .bootbox-close-button {
  margin-top: 0 !important;
}

.amcharts-graph-g2 .amcharts-graph-stroke {
  -webkit-animation: am-moving-dashes 1s linear infinite;
  animation: am-moving-dashes 1s linear infinite;
}

.lastBullet {
  -webkit-animation: am-pulsating 1s ease-out infinite;
  animation: am-pulsating 1s ease-out infinite;
}

.amcharts-graph-column-front {
  -webkit-transition: all 0.3s 0.3s ease-out;
  transition: all 0.3s 0.3s ease-out;
}

.amcharts-graph-column-front:hover {
  fill: #496375;
  stroke: #496375;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.amcharts-graph-g3 {
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-dasharray: 500%;
  stroke-dasharray: 0\9;
  stroke-dashoffset: 0\9;
  -webkit-animation: am-draw 40s;
  animation: am-draw 40s;
}

/*--------------------------------------------------
    8. Animated Pie Chart
----------------------------------------------------*/
.animated-pie-chart,
.columnLine {
  width: 100%;
  height: 350px;
  font-size: 11px;
}

.amcharts-graph-graph2 .amcharts-graph-stroke {
  stroke-dasharray: 4px 5px;
  stroke-linejoin: round;
  stroke-linecap: round;
  -webkit-animation: am-moving-dashes 1s linear infinite;
  animation: am-moving-dashes 1s linear infinite;
}

.cke {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
}

.cke .cke-top {
  -webkit-border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  -ms-border-radius: 2px 2px 0 0;
  -o-border-radius: 2px 2px 0 0;
  border-radius: 2px 2px 0 0;
}

.cke .cke-bottom {
  -webkit-border-radius: 0 0 2px 2px;
  -moz-border-radius: 0 0 2px 2px;
  -ms-border-radius: 0 0 2px 2px;
  -o-border-radius: 0 0 2px 2px;
  border-radius: 0 0 2px 2px;
}

.cke_bottom,
.cke_dialog,
.cke_dialog_footer,
.cke_dialog_title,
.cke_inner,
.cke_reset,
.cke_top {
  background-image: none !important;
  filter: none;
  border-top: 0;
  border-bottom: 0;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  text-shadow: none;
}

.cke_dialog_tab,
.cke_dialog_ui_button,
.cke_dialog_ui_input_text {
  filter: none;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  background-image: none !important;
}

.cke_dialog_tab,
.cke_dialog_ui_button {
  box-shadow: none !important;
  text-shadow: none !important;
}

.cke_dialog_tab:hover,
.cke_dialog_ui_button:hover {
  text-decoration: none;
  text-shadow: none;
}

.cke_dialog_ui_input_text {
  box-shadow: none !important;
}

.cke_button,
.cke_combo_button,
.cke_toolbar,
.cke_toolgroup {
  background-image: none !important;
  filter: none !important;
  border: 0;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.cke_button,
.cke_combo_button,
.cke_hc.cke_panel_listItem a,
.cke_panel_grouptitle {
  background-image: none !important;
  filter: none;
  text-shadow: none;
}

.cke_button:hover,
.cke_combo_button:hover {
  background-color: #ddd;
}

.cke_toolbar_break {
  background-image: none !important;
  filter: none !important;
  border: 0;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -ms-box-shadow: none !important;
  -o-box-shadow: none !important;
}

.has-error .cke {
  border: 1px solid #fbe1e3 !important;
}

.has-success .cke {
  border: 1px solid #abe7ed !important;
}

.has-warning .cke {
  border: 1px solid #f9e491 !important;
}

.modal-open .clockface {
  z-index: 10055 !important;
}

.clockface .cell .inner.active,
.clockface .cell .outer.active {
  background-color: #4b8df8 !important;
  background-image: none;
  filter: none;
}
/************************************
	9. Data tables
*************************************/
.table-checkable tr > td:first-child,
.table-checkable tr > th:first-child {
  text-align: center;
  max-width: 50px;
  min-width: 40px;
  padding-left: 0;
  padding-right: 0;
}

table.dataTable thead td,
table.dataTable thead th {
  border-bottom: 2px solid #e7ecf1;
  outline: 0 !important;
}

table.dataTable.no-footer {
  border-bottom-color: #e7ecf1;
}

.dataTables_extended_wrapper.DTS .dataTables_scrollBody,
.dataTables_extended_wrapper.DTS .dataTables_scrollHead {
  border-bottom: 0 !important;
}

table.dataTable tr.heading > th {
  background-color: #fbfcfd;
}

table.dataTable td.sorting_1,
table.dataTable td.sorting_2,
table.dataTable td.sorting_3,
table.dataTable th.sorting_1,
table.dataTable th.sorting_2,
table.dataTable th.sorting_3 {
  background: #fbfcfd !important;
}

.paging_bootstrap_extended {
  margin: 5px 0 0 !important;
  padding: 0 !important;
  float: none !important;
  font-size: 13px;
}

.dataTables_extended_wrapper {
  margin-top: 10px;
}

.dataTables_extended_wrapper .seperator {
  padding: 0 2px;
}

.dataTables_extended_wrapper .table.dataTable {
  margin: 20px 0 !important;
}

.dataTables_extended_wrapper div.dataTables_info,
.dataTables_extended_wrapper div.dataTables_length,
.dataTables_extended_wrapper div.dataTables_paginate {
  display: inline-block;
  float: none !important;
  padding: 0 !important;
  margin: 0 !important;
  position: static !important;
}

.dataTables_extended_wrapper .table-group-actions > span {
  font-size: 13px;
}

.dataTables_extended_wrapper.DTS .paging_bootstrap_extended {
  display: inline-block;
}

.dataTables_extended_wrapper.DTS .dataTables_info {
  display: inline-block;
  padding-top: 8px !important;
}

.dataTables_extended_wrapper.DTS .dataTables_info .seperator {
  display: none;
}

.dataTables_extended_wrapper.DTS
  .dataTables_scrollHead
  .dataTables_scrollHeadInner
  .table.dataTable {
  margin-bottom: 0 !important;
}

.dataTables_extended_wrapper.DTS .dataTables_scrollBody .table.dataTable {
  margin: 0 !important;
}

.dataTables_extended_wrapper div.dataTables_length label {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 13px;
  float: none !important;
  display: inline-block !important;
}

.table-container .table-actions-wrapper {
  display: none;
}

.dataTables_scroll {
  margin-bottom: 10px;
}

.dataTables_scrollHead {
  border-bottom: 2px solid #e7ecf1 !important;
}

.dataTables_scrollHead thead th {
  border-bottom: 0 !important;
}

.dataTables_scrollBody {
  border-bottom: 1px solid #e7ecf1 !important;
}

.table-both-scroll .dataTables_scrollBody {
  border-bottom: 0 !important;
}

.dataTables_wrapper .dataTables_processing {
  width: 200px;
  display: inline-block;
  padding: 7px;
  left: 50%;
  margin-left: -100px;
  margin-top: 10px;
  text-align: center;
  color: #3f444a;
  border: 1px solid #e7ecf1;
  background: #eef1f5;
  vertical-align: middle;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

.dt-button-background {
  display: none !important;
}

div.dt-button-collection {
  border: 1px solid #eee;
  background: #fff;
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  padding: 0;
}

div.dt-button-collection > a.dt-button {
  background: 0 0;
  padding: 8px 16px;
  font-weight: 300;
  margin: 1px;
  box-shadow: none !important;
  border: 0 !important;
}

div.dt-button-collection > a.dt-button > span {
  font-size: 14px;
  color: #333;
}

div.dt-button-collection > a.dt-button.active {
  background: #f6f6f6 !important;
  box-shadow: none !important;
  border: 0 !important;
}

div.dt-button-collection > a.dt-button.active > span {
  color: #333;
}

a.dt-button,
button.dt-button,
div.dt-button {
  background-image: none !important;
  padding: 6px 12px !important;
}

a.dt-button:last-child,
button.dt-button:last-child,
div.dt-button:last-child {
  margin-right: 0;
}

.dataTables_wrapper .dt-buttons {
  float: left;
}

/* .dataTable .row-details.row-details-close {
  background: url(../img/datatable-row-openclose.png) no-repeat;
}

.dataTable .row-details.row-details-open {
  background: url(../img/datatable-row-openclose.png) 0 -23px no-repeat;
} */

.dataTable td .popover-content .btn-group {
  position: static !important;
}
.table .btn-group .btn {
  margin-left: 0;
  margin-right: -1px;
}
.buttons-copy {
  background-color: #666 !important;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px #666;
  color: #fff !important;
  border-radius: 30px !important;
  border: 0px !important;
  height: 30px;
  width: 60px;
}

.buttons-excel {
  background-color: #59bf70 !important;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px #59bf70;
  color: #fff !important;
  border-radius: 30px !important;
  border: 0px !important;
  height: 30px;
  width: 60px;
}

.buttons-csv {
  background-color: #2ab9d0 !important;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px #2ab9d0;
  color: #fff !important;
  border-radius: 30px !important;
  border: 0px !important;
  height: 30px;
  width: 60px;
}

.buttons-pdf {
  background-color: #e91e63 !important;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px #e91e63;
  color: #fff !important;
  border-radius: 30px !important;
  border: 0px !important;
  height: 30px;
  width: 60px;
}
.buttons-print {
  background-color: #6563ef !important;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px #6563ef;
  color: #fff !important;
  border-radius: 30px !important;
  border: 0px !important;
  height: 30px;
  width: 60px;
}
td.details-control {
  background: url("../img/details_open.png") no-repeat center center;
  cursor: pointer;
}
tr.shown td.details-control {
  background: url("../img/details_close.png") no-repeat center center;
}
/*******************************
	10. Dropzone
********************************/
.dropzone {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.dropzone-file-area {
  border: 2px dashed #028af4;
  background: #fff;
  padding: 20px;
  margin: 0 auto;
  text-align: center;
}
.dz-hidden-input {
  left: 0;
}
.fancybox-overlay {
  z-index: 100000;
}
.fancybox-opened {
  z-index: 100001;
}
/*******************************
	11. Spinner
********************************/
.spinner-buttons.btn-group-vertical .btn {
  text-align: center;
  margin: 0;
  height: 17px;
  width: 22px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 0;
}
.page-style-rounded .spinner-buttons.btn-group-vertical .btn.spinner-up {
  border-radius: 0;
  border-top-right-radius: 2px !important;
}
.page-style-rounded .spinner-buttons.btn-group-vertical .btn.spinner-down {
  border-radius: 0 0 2px;
}

/***********************************************
	12. Fullcalendar
***********************************************/

.external-event {
  display: inline-block;
  cursor: move;
  margin-bottom: 5px;
  margin-left: 5px;
}
.fc-scroller {
  overflow-y: auto;
  overflow-x: hidden;
}
.fc-month-view .fc-scroller {
  height: auto !important;
}

/**********************************************
	13. Google Maps
**********************************************/

.gmaps {
  height: 300px;
  width: 100%;
}
.gmaps img {
  max-width: none;
}
#gmap_static div {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: block;
  height: 300px;
}
#gmap_routes_instructions {
  margin-top: 10px;
  margin-bottom: 0;
}

.blueimp-gallery .next,
.blueimp-gallery .prev {
  border-radius: 23px !important;
}
.jquery-notific8-message {
  font-size: 13px;
}
[class*="jquery-notific8"],
[class*="jquery-notific8"]:after,
[class*="jquery-notific8"]:before {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.left .jquery-notific8-close-sticky span,
.right .jquery-notific8-close-sticky span {
  font-size: 10px;
}
.jquery-notific8-heading {
  font-weight: 300;
  font-size: 16px;
}
.scroller {
  padding: 0 12px 0 0;
  margin: 0;
  overflow: hidden;
}

.jqstooltip {
  width: auto !important;
  height: auto !important;
}
.slider {
  border: 0;
  padding: 0;
  display: block;
  margin: 12px 5px;
  min-height: 11px;
}
.ui-slider-vertical {
  width: 11px;
}
.ui-slider-horizontal .ui-slider-handle {
  top: -3px;
}
.ui-slider-vertical .ui-slider-handle {
  left: -3px;
}
.ui-slider-handle,
.ui-slider-vertical {
  filter: none !important;
  background-image: none !important;
}

/*************************************
				14. Vector Map
*****************************************/

.jqvmap-zoomin,
.jqvmap-zoomout {
  height: 16px;
  width: 16px;
  background-color: #666;
}
.vmaps {
  position: relative;
  overflow: hidden;
  height: 300px;
}
/*************************************
				15. Morris Chart
*****************************************/
.morris-hover.morris-default-style .morris-hover-row-label {
  text-align: left;
  font-weight: 400;
  font-size: 15px;
  color: #7d8c9d;
  font-family: Poppins, sans-serif;
}
.morris-hover.morris-default-style .morris-hover-point {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}
.morris-hover.morris-default-style .morris-hover-point,
.select2-container--bootstrap .select2-results__group,
.select2-container--bootstrap .select2-selection {
  font-family: Poppins, sans-serif;
}

.form-recaptcha-img {
  margin-bottom: 10px;
  clear: both;
  border: 1px solid #e5e5e5;
  padding: 5px;
}
iframe[src="about:blank"] {
  display: none;
}

/***********************************
			16. Select2
***********************************/

.select2-container--bootstrap .select2-search--dropdown .select2-search__field,
.select2-container--bootstrap .select2-selection,
.select2-container--bootstrap.select2-container--focus .select2-selection,
.select2-container--bootstrap.select2-container--open .select2-selection {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.select2-container--bootstrap
  .select2-selection--multiple
  .select2-selection__choice__remove {
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  margin-right: 3px;
}
.select2-container--bootstrap .select2-results__group {
  display: block;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 600;
}
.modal-open .select2-dropdown {
  z-index: 10060;
}
.modal-open .select2-close-mask {
  z-index: 10055;
}
.modal-open
  .select2-container--bootstrap
  .select2-selection--multiple
  .select2-search--inline
  .select2-search__field {
  width: auto !important;
}
.select2-result-repository {
  padding-top: 4px;
  padding-bottom: 3px;
}
.select2-result-repository__avatar {
  float: left;
  width: 60px;
  margin-right: 10px;
}
.select2-result-repository__avatar img {
  width: 100%;
  height: auto;
  border-radius: 2px;
}
.select2-result-repository__meta {
  margin-left: 70px;
}
.select2-result-repository__title {
  color: #000;
  font-weight: 700;
  word-wrap: break-word;
  line-height: 1.1;
  margin-bottom: 4px;
}
.select2-result-repository__forks,
.select2-result-repository__stargazers {
  margin-right: 1em;
}
.select2-result-repository__forks,
.select2-result-repository__stargazers,
.select2-result-repository__watchers {
  display: inline-block;
  color: #aaa;
  font-size: 11px;
}
.select2-result-repository__description {
  font-size: 13px;
  color: #777;
  margin-top: 4px;
}
.select2-results__option--highlighted .select2-result-repository__title {
  color: #fff;
}
.select2-results__option--highlighted .select2-result-repository__description,
.select2-results__option--highlighted .select2-result-repository__forks,
.select2-results__option--highlighted .select2-result-repository__stargazers,
.select2-results__option--highlighted .select2-result-repository__watchers {
  color: #c1d7e9;
}
.select2-selection__choice {
  background-color: #eee !important;
  border: 1px solid #eee !important;
}
.select2-selection__choice .select2-selection__choice__remove {
  font-weight: 400 !important;
}
/***********************************
	17. iCheck
***********************************/

.icheck-list > label {
  display: block;
  margin-bottom: 8px;
}
.icheck-list > label:last-child {
  margin-bottom: 0;
}
.form-horizontal .icheck-inline {
  margin-top: 8px;
}
.icheck-inline > label {
  margin-left: 15px;
}
.icheck-inline > label:first-child {
  margin-left: 0;
}
div[class*="icheckbox_"],
div[class*="iradio_"] {
  margin-right: 5px;
  top: -1px !important;
}
.ui-select-match-close {
  margin-top: 5px !important;
  margin-left: 5px !important;
}
.minicolors-swatch {
  border: 0;
}
pre[class*="language-"] {
  padding: 10px;
  background: 0 0 !important;
  border: 1px solid #f1f4f7 !important;
  box-shadow: none !important;
  margin: 0;
}
pre[class*="language-"] code {
  background: 0 0 !important;
  box-shadow: none !important;
  border: 0 !important;
}
/***********************************
			18. Color Picker
***********************************/
.colorpicker.dropdown-menu,
.daterangepicker.dropdown-menu,
.datetimepicker.dropdown-menu {
  padding: 5px;
  right: auto !important;
}

#barChart,
#barchart2,
#barImg,
#lineChart,
#donutChart,
#pieChart,
#gaugeChart,
#radialLineChart,
#dumbbellPlotChart {
  width: 100%;
  height: 400px;
}
#mapBubble {
  width: 100%;
  height: 500px;
}
