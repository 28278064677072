.Toastify__toast--error {
    border: 1px solid #EB5757;
    border-radius: 50px !important;
    background: #FAE1E2 !important;
}

.Toastify__toast--error::after {
  /* content : url('../assets/images/svg/closeToast.svg'); */
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left:265px;
  padding-top: 14px !important;
}
.Toastify__toast--error::before {
  /* content: url("../assets/images/svg/errorToast.svg"); */
  position:relative; 
  z-index:100000;
  left:12px;
  top:6px;
}

.Toastify__toast--success {
  border: 1px solid #3A9EA5 !important;
  border-radius: 50px !important;
  background: #F0F9FA !important;
}

.Toastify__toast--warning {
  border: 1px solid #E78326  !important;
  border-radius: 50px !important;
  background: #FADFC5 !important;
}


.Toastify__toast--info {
    border: 1px solid #4548e6  !important;
    border-radius: 50px !important;
    background: #a8def7 !important;
  }



.Toastify__close-button {
    display: none;
}
